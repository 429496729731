import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { HybridLink } from "src/components";
import "./helpers.css";

export const faqContent = {
  cook: [
    {
      id: 1,
      question: (
        <Typography variant="body2" component="p" className="question">
          What is COOK Connect?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Connect is the first legal marketplace for home cooked food
            operated by a nonprofit. Our platform was built for and by home
            cooks to actualize the local food system we all deserve.{" "}
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            For home-based culinary entrepreneurs, COOK Connect provides an
            all-in-one sales and order management platform designed to turn a
            food dream into a successful business— from the comfort of home.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            As a nonprofit, all proceeds from our platform are reinvested into
            supporting chefs and the home cooking movement through programming,
            training, and small business support. Join us as we work to empower
            food entrepreneurship, build community, and redefine our
            relationship to our food!
            <HybridLink
              className="link"
              href={"https://www.cookalliance.org/story"}
            >
              Learn more about COOK Alliance here.
            </HybridLink>
          </Typography>
        </section>
      ),
    },
    {
      id: 2,
      question: (
        <Typography variant="body2" component="p" className="question">
          What is a Microenterprise Home Kitchen?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            Microenterprise Home Kitchen Operations (MEHKOs) are ‘micro’ food
            facilities operated by home food entrepreneurs out of their
            permitted residential kitchens. They provide home cooks
            opportunities to make income doing what they love, and offer diners
            fresh & new local cuisine that can’t be found anywhere else. They
            are the future of food! COOK Alliance has been working to legalize
            Microenterprise Home Kitchen Operations (MEHKOs) across the country,
            beginning with California. Please note that this law is not active
            everywhere;{" "}
            <HybridLink
              className="link"
              href={"https://www.cookalliance.org/resources"}
            >
              consult our resources page
            </HybridLink>{" "}
            to discover which areas have adopted MEHKOs.
          </Typography>
        </section>
      ),
    },
    {
      id: 3,
      question: (
        <Typography variant="body2" component="p" className="question">
          What are the permit requirements for a MEHKO?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            To operate a Microenterprise Home Kitchen Operation (MEHKO) in
            California you’ll need a permit from your local environmental health
            agency. This includes submitting your intended menu and operating
            plans, passing a home kitchen inspection and completing a Food
            Manager certification. MEHKOs are limited to direct customer sales,
            with food prepared, cooked, and served on the same day. There are
            also operational limits, allowing up to 30 meals daily (or 90 meals
            weekly) and gross annual sales not exceeding $100,000​​​​ (adjusted
            annually for inflation).
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            For more comprehensive details, you can{" "}
            <HybridLink
              className="link"
              href={
                "https://www.cdph.ca.gov/Programs/CEH/DFDCS/CDPH%20Document%20Library/FDB/FoodSafetyProgram/MEHKO/Microenterprise%20Home%20Kitchen%20Operations%20General%20Permit%20Requirements.pdf"
              }
            >
              refer to this document from CDPH
            </HybridLink>{" "}
            (note that the sales limits have increased since this was published)
            or take a look at this{" "}
            <HybridLink
              className="link"
              href={
                "http://publichealth.lacounty.gov/eh/docs/business/MEHKORoadMapOfPermittingProcess-ENG.pdf"
              }
            >
              sample permitting roadmap
            </HybridLink>{" "}
            from Los Angeles County (note that the process may differ slightly
            in your County).
          </Typography>
        </section>
      ),
    },

    {
      id: 3,
      question: (
        <Typography variant="body2" component="p" className="question">
          Why should I operate my business on COOK Connect?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Alliance (operator of COOK Connect) has been at the forefront
            of the legally permitted home cooking economy since the very
            beginning—we co-wrote the law that legalized MEHKOs! To date, we’ve
            supported hundreds of MEHKO cooks in permitting and growing their
            businesses from the ground up.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Here are just a few of the services we provide to home cooks looking
            to take their business to the next level:
          </Typography>
          <ul className="unorderedList">
            <Typography variant="body2" component="li" className="answer">
              Exposure to our Network of Diners Through Our Marketplace
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              The Easiest & Simplest Ordering System Tailored for Homemade Food
              Orders
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              Automated Text & Email Marketing To Keep Your Customers Coming
              Back
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              Industry-Leading Privacy & Safety Reinforced Through our Platform
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              Automatic Collection & Payment of Your Sales Tax
            </Typography>
          </ul>
        </section>
      ),
    },
    {
      id: 4,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I get started selling my food?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          Can’t wait to get started? Currently COOK Connect is available by
          invitation-only as well as to members of the public who complete our
          COOK Academy program– which is open for enrollment!{" "}
          <HybridLink
            className="link"
            href={"https://www.cookalliance.org/cookacademy"}
          >
            Click here to register for COOK Academy.
          </HybridLink>
        </Typography>
      ),
    },
    {
      id: 5,
      question: (
        <Typography variant="body2" component="p" className="question">
          How much money can I expect to make?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            How much you make is a function of how often you cook, how well you
            promote your business, and how much diners love your food. It takes
            time to build up a loyal fanbase of customers; California’s MEHKO
            Law allows you to serve up to $100,000 in homemade food sales,
            adjusted annually for inflation.
          </Typography>
        </section>
      ),
    },
    {
      id: 6,
      question: (
        <Typography variant="body2" component="p" className="question">
          What are the fees to use COOK Connect?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Connect charges a platform fee of 7% to diners, plus a 2.9%
            standard electronic payment processing fee (charged to us by Paypal
            / Stripe). This means you will receive 100% of your sales directly,
            and customers will just pay a small service fee. As a nonprofit,
            proceeds from the platform are reinvested into supporting chefs and
            the home cooking movement through programs, training, and small
            business support.
          </Typography>
        </section>
      ),
    },
    {
      id: 7,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I get paid on COOK Connect?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          We issue payments to our MEHKO owners through Paypal, as it is the
          safest way to hold your money and prevent fraud. You will need a
          Paypal Business account. Your payments are deposited within 48 hours
          of the close of your day’s hours of operation, every day.
        </Typography>
      ),
    },
    {
      id: 8,
      question: (
        <Typography variant="body2" component="p" className="question">
          How much does it cost to start a MEKHO?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            The average cost for all required permits and certifications for a
            MEHKO is just below $800. Many of the required fees vary by your
            location— if you enroll in COOK Academy you may be eligible for a
            microgrant to cover all of these costs:
          </Typography>
          <ul className="unorderedList">
            <Typography variant="body2" component="li" className="answer">
              $99 for Food Manager’s Certification Course & Exam
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              $300-$651 for your Health Department Permit (varies by County)
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              $0-$150 for Local Business License (varies by City)
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              $0-$100 in Food Safety Supplies (you may have everything you need
              already!)
            </Typography>
          </ul>
        </section>
      ),
    },
    {
      id: 9,
      question: (
        <Typography variant="body2" component="p" className="question">
          What can I cook?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            Almost anything! The only things you{" "}
            <span style={{ textDecoration: "underline" }}>cannot</span> prepare
            from your Home Restaurant are:
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Raw oysters, raw milk, dairy-based ice cream, fermented foods,
            cannabis-infused foods, sousvide or reduced oxygen packaging, cured
            meats, dishes that require a HACCP plan, and leftovers (all food
            must be prepared and served on the same day of service).
          </Typography>
        </section>
      ),
    },
    {
      id: 10,
      question: (
        <Typography variant="body2" component="p" className="question">
          How does COOK Connect keep its home cooks safe?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            Safety and Privacy are two of COOK Connect’s core values. Here are a
            few of the ways our platform protects you and your business:
          </Typography>

          <ul className="unorderedList">
            <Typography variant="body2" component="li" className="answer">
              COOK Connect hides your private address and instead shows
              potential customers your approximate location — your address is
              only sent out to your customer after they complete their purchase
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              We keep digital records of the customers that purchase from you,
              for legal protection
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              Our in-app chat system allows you to communicate with your
              customers without giving out your personal information
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              We automatically run fraud checks on all payments, to ensure all
              of your payments are legitimate and your money is protected
            </Typography>
          </ul>
        </section>
      ),
    },
    {
      id: 11,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do taxes work?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          COOK Connect collects 100% of your sales tax for you and remits it on
          your behalf! We issue you annual income summaries that you then submit
          to your tax agency. This takes a large accounting burden off of your
          plate as an independent food entrepreneur.Please see our{" "}
          <HybridLink
            className="link"
            href={
              "https://docs.google.com/document/d/19OJWcQ5qumRbhvbK0XbR9dglmhBXsVgz2o_ulRKnKS8/edit?tab=t.0"
            }
          >
            guide to taxes here
          </HybridLink>
          .
        </Typography>
      ),
    },

    {
      id: 12,
      question: (
        <Typography variant="body2" component="p" className="question">
          How does my food get to my customers?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            One of our platform’s core features is a streamlined system for
            curbside pickup. Customers schedule their order on COOK Connect,
            receive reminder notifications, and notify you through the COOK
            Connect app when they are outside your home for pickup.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            While 3rd party delivery is not permitted, you can have a family or
            household member offer delivery for your MEHKO! The COOK Connect
            platform enables easy delivery for your own drivers—you set your own
            hourly rate, minimum order size, maximum drive distance, and we
            charge your customer based on live traffic data!
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Chefs with the space to provide dine-in meal experiences in their
            home have also seen significant success with their businesses. Many
            people are willing to pay extra for the unique experience of eating
            inside someone’s home and experiencing their cuisine and culture
            firsthand. With great hospitality, you can turn a one-time diner
            into a lifelong customer!
          </Typography>
        </section>
      ),
    },
    {
      id: 13,
      question: (
        <Typography variant="body2" component="p" className="question">
          Can I have my food delivered by Doordash / UberEats / Grubhub /
          Someone Else?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Connect currently does not support any third-party delivery
            services. In general, MEHKO laws do not allow 3rd party delivery
            services to deliver food, except to an individual who has a physical
            or mental disability which limits their ability to access the food
            otherwise.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            If another company claims they can deliver your food for you, they
            are likely not doing so in accordance with the law.
          </Typography>
        </section>
      ),
    },
    {
      id: 14,
      question: (
        <Typography variant="body2" component="p" className="question">
          How often do I need to cook on COOK Connect?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          You can serve on COOK Connect as little or as often as you like.
          However, MEHKOs that cook more frequently tend to perform better, as
          they are able to consistently engage their customers and keep them
          coming back!
        </Typography>
      ),
    },
    {
      id: 15,
      question: (
        <Typography variant="body2" component="p" className="question">
          Can I have an employee for my MEHKO?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          Yes! Household and family members are permitted to assist with your
          MEHKO operation, and you are allowed one additional employee beyond
          that. Anyone assisting with food preparation will need a Food Handlers
          card.
        </Typography>
      ),
    },
    {
      id: 16,
      question: (
        <Typography variant="body2" component="p" className="question">
          Will my MEHKO permit allow me to serve at Farmer’s Markets and Events?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          As your MEHKO permit is exclusively tied to you and your home kitchen,
          you will need separate permits and certifications to prepare and serve
          food anywhere outside of your home.
        </Typography>
      ),
    },
    {
      id: 17,
      question: (
        <Typography variant="body2" component="p" className="question">
          Should I get liability insurance for my MEHKO business?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            Running a MEHKO is an exciting opportunity, but like any business,
            it's important to manage risk. Operating a home-based food business
            carries inherent risks, such as potential accidents, food-related
            incidents, or other liability concerns. That's why we recommend
            having insurance—to protect yourself, your customers, and your
            business.
          </Typography>
          <Typography variant="body2" component="p" className="question">
            We are currently unable to provide liability insurance to cover any
            issues that might arise from food listed or promoted on the
            platform. However, we are happy to connect our active COOK Connect
            cooks with carriers and brokers who can help you find the right
            policy. {" "}
          </Typography>
        </section>
      ),
    },
  ],
  diner: [
    {
      id: 1,
      question: (
        <Typography variant="body2" component="p" className="question">
          What is COOK Connect?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="question">
            COOK Connect is the first legal marketplace for home cooked food
            operated by a nonprofit. Our platform was built for and by home
            cooks to actualize the local food system we all deserve.
          </Typography>
          <Typography variant="body2" component="p" className="question">
            For eaters, COOK Connect offers a way to find authentic food made
            with love by permitted home cooks in your neighborhood. Supporting
            local family businesses and discovering real, diverse food options,
            have never been easier.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            As a nonprofit, all proceeds from our platform are reinvested into
            supporting chefs and the home cooking movement through programming,
            training, and small business support. Join us as we work to empower
            food entrepreneurship, build community, and redefine our
            relationship to our food! Learn more about COOK Alliance{" "}
            <HybridLink
              className="link"
              href={"https://www.cookalliance.org/story"}
            >
              here
            </HybridLink>
          </Typography>
        </section>
      ),
    },
    {
      id: 2,
      question: (
        <Typography variant="body2" component="p" className="question">
          What is a Microenterprise Home Kitchen?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          Microenterprise Home Kitchen Operations (MEHKOs) are ‘micro’ food
          facilities operated by Food Safety-trained chefs out of their
          permitted residential kitchens. They provide home cooks opportunities
          to make income doing what they love, and offer you fresh & new local
          cuisine that you can’t find anywhere else. They are the future of
          food!
        </Typography>
      ),
    },
    {
      id: 3,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I get my food?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            One of our platform’s core features is a streamlined system for
            curbside pickup. You can schedule your order on COOK Connect,
            receive reminder notifications, and notify your cook through the
            COOK Connect app when you are outside your cook’s home for pickup.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Some of our cooks offer their own delivery as well! As cooks have
            complete control of their business, you may see variability in the
            cost for delivery across different MEHKOs.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Select chefs also offer yummy and unique dine-in meal experiences in
            their homes! Perfect for adventurous foodies, you can meet the chef
            and experience their cuisine and culture firsthand! Space is
            limited, and dine-in options are first-come-first serve where
            available.
          </Typography>
        </section>
      ),
    },
    {
      id: 4,
      question: (
        <Typography variant="body2" component="p" className="question">
          Is the food safe?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            Yes! After tens of thousands of homemade meals sold, permitted
            MEHKOs have maintained a near 100% food safety record, with 0
            verified cases of foodborne illness to date.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Nothing is more important to us than community health, and the
            safety of your food.
            <span className="bold">
              {" "}
              To that end, the following are required for all COOK Connect
              cooks:{" "}
            </span>
          </Typography>
          <ul className="unorderedList">
            <Typography variant="body2" component="li" className="answer">
              <span className="bold">
                Intensive inspection & permitting process
              </span>
              <Typography variant="body2" component="p">
                All COOK Connect cooks have their kitchen and preparation
                protocols reviewed, inspected, and approved by a panel of the
                county’s top health officials.
              </Typography>
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              <span className="bold">
                Rigorous food safety trainings & certifications
              </span>
              <Typography variant="body2" component="p">
                All COOK Connect cooks have received Food Safety Manager
                Certification—a higher standard than regular restaurant workers.
              </Typography>
            </Typography>
            <Typography variant="body2" component="li" className="answer">
              <span className="bold">Unmatched transparency</span>
              <Typography variant="body2" component="p">
                At MEHKOs, you meet the cook — the face of their business. Home
                cooks prepare your meals with the same level of care they have
                for their families.
              </Typography>
            </Typography>
          </ul>
        </section>
      ),
    },
    {
      id: 5,
      question: (
        <Typography variant="body2" component="p" className="question">
          How can I submit feedback from my meal & experience?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          After each meal, you will receive a text and email notification
          allowing you to leave a review and a tip for your home cook! Reviews
          are an essential way that COOK Connect fans share their experience,
          and provide invaluable feedback for the chef to continue to improve
          their food and service. COOK Connect diners have submitted more than
          5,000 5-star reviews of their meals to date, and counting!
        </Typography>
      ),
    },
    {
      id: 6,
      question: (
        <Typography variant="body2" component="p" className="question">
          How is my privacy protected?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          COOK Connect takes the privacy of our diners and cooks very seriously.
          Feel free to take a look at our{" "}
          <Link to="/privacy" className="link">
            privacy policy{" "}
          </Link>{" "}
          for a full rundown of how we protect your information.
        </Typography>
      ),
    },
    {
      id: 7,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I report a food safety concern?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          We take food safety seriously, and we would love to know if you have a
          problem with your food or how it was prepared or handled. Please login
          and navigate to the “order summary” screen where you will see a “file
          complaint” button. From here, you can either contact COOK Connect
          directly with food safety or hygiene complaints, or click through to
          our directory of Health Department Contact information to contact your
          local enforcement agency directly. For general concerns not linked to
          an order, you can contact us at support@cookalliance.org.
        </Typography>
      ),
    },
    {
      id: 8,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I place an order?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          COOK Connect allows you to customize your order and checkout in just 5
          clicks! Head over to{" "}
          <Link to="/menus" className="link">
            cookconnect.com/menus
          </Link>
          , browse through home restaurant menus near you, add dishes to your
          order and checkout in just a few steps. The full address is given to
          you after your order is completed!
        </Typography>
      ),
    },
    {
      id: 9,
      question: (
        <Typography variant="body2" component="p" className="question">
          Why can’t I see the home restaurant’s address?
        </Typography>
      ),
      answer: (
        <Typography variant="body2" component="p" className="answer">
          As these MEHKOs are operated out of our chefs’ personal residences,
          exact addresses are shared when you complete your order to protect the
          cook’s privacy. On each MEHKO profile and menu page, we display the
          pickup location within a 1/2 mile radius, so you can plan your trip to
          pick up!
        </Typography>
      ),
    },
    {
      id: 10,
      question: (
        <Typography variant="body2" component="p" className="question">
          I’m outside of my cook’s house to pickup. Now what do I do?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            You will receive a confirmation text message when you complete your
            purchase, and 1hr before your scheduled pickup time, with all of the
            details of your order. In these text messages are digital ‘Doorbell’
            links that notify your cook when you are outside their home and
            ready for pickup. Press the link & you will see a message saying
            that your cook has been notified that you are outside — kick back
            and your food will be brought out to your car shortly after!
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            If for any reason you did not receive these texts, you can always
            access this ‘Doorbell’ link by logging in to your COOK Connect
            profile, tapping on ‘Orders’ at the bottom (or top right corner on
            desktop) of your display, and selecting your order in the ‘Upcoming
            Menus’ section.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            You can additionally send your cook a message directly through the
            chat button located on your receipt page & on the cook’s profile &
            menu pages.
          </Typography>
        </section>
      ),
    },
    {
      id: 11,
      question: (
        <Typography variant="body2" component="p" className="question">
          How do I get help with specific questions about my order?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Connect has a straightforward chat system that allows you to
            speak directly with any chef you’d like! You can start to chat with
            a chef by heading to their MEHKO profile or menu page, and
            tapping/clicking on the ‘Chat’ button beneath their profile photo!
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            **During their food service hours, please be patient with allowing
            your chefs time to respond, as they are often both the head chef and
            only customer service representative for their MEHKO. They will
            respond as soon as they get a moment between preparing your
            delicious plates!
          </Typography>
        </section>
      ),
    },
    {
      id: 12,
      question: (
        <Typography variant="body2" component="p" className="question">
          What if I need to cancel my order?
        </Typography>
      ),
      answer: (
        <section className="answserContainer">
          <Typography variant="body2" component="p" className="answer">
            As your chef prepares your dish fresh on the day of your scheduled
            pickup, order cancellations are allowed up to 24 hours before your
            scheduled time. This order cancellation can be done from your COOK
            Connect profile by heading over to your ‘Orders’ tab, selecting the
            Order you’d like to cancel from your ‘Upcoming Menus’, and tapping
            the ‘Cancel Order’ button on your receipt.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            Please note that Order Cancellations issue your funds as Credits in
            your COOK Connect account, to be used towards your next purchase.
            For a full refund to your bank account or Paypal, please see our
            policy below!
          </Typography>
        </section>
      ),
    },
    {
      id: 13,
      question: (
        <Typography variant="body2" component="p" className="question">
          What is COOK Connect's refund policy?
        </Typography>
      ),
      answer: (
        <section className="answerContainer">
          <Typography variant="body2" component="p" className="answer">
            COOK Connect issues full refunds to your bank account up to 24 hours
            before your scheduled pickup time. Please reach out to our team
            using the contact below to secure your refund! Within 24 hours, we
            do not issue refunds, as your home cook has already purchased
            ingredients or begun preparing your meal.
          </Typography>
          <Typography variant="body2" component="p" className="answer">
            With that said, we are committed to excellent customer service— we
            are not satisfied until your belly is happy! We would love to
            connect with you to resolve any issues with your order. Please send
            us an email at{" "}
            <a href="mailto:support@cookalliance.org" className="link">
              support@cookalliance.org
            </a>{" "}
            {/* or give us a call at{" "}
            <a href="tel:9518800422" className="link">
              (951)-880-0422
            </a>{" "} */}
            and we would be happy to assist you.
          </Typography>
        </section>
      ),
    },
  ],
};
